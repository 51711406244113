import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { Mail, Phone, ShoppingCart } from 'react-feather'

interface Props {
  email?: string
  IBEID?: number
  languageCode: string
  languagePrefix: string | null
  phone?: string
}

export const Toolbars = memo(function Toolbars({
  languageCode,
  languagePrefix,
  email,
  phone,
  IBEID,
}: Props) {
  return (
    <Container dial={5} row space="between">
      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Mobile Phone',
                })
            }
          }}
        >
          <Phone />
          <Label>{useVocabularyData('telephone', languageCode)}</Label>
        </Item>
      ) : null}
      {email ? (
        <Item
          href={`mailto: ${email}`}
          target="_blank"
          onClick={() => {
            typeof window.gtag !== 'undefined' &&
              window.gtag('event', 'Click', {
                event_category: 'Website',
                event_label: 'Email Address',
              })
          }}
        >
          <Mail />
          <Label>{useVocabularyData('email', languageCode)}</Label>
        </Item>
      ) : null}
      {IBEID ? (
        <Item
          href={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
            languagePrefix ? languagePrefix : 'it'
          }`}
          rel="noopener"
          target="_blank"
        >
          <ShoppingCart />
          <Label>{useVocabularyData('book', languageCode)}</Label>
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 0.1875rem
    3.75rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease-out;
`

const Item = styled.a`
  text-align: center;
  padding: 18px 1.25rem;
  width: 33.333%;

  svg {
    width: auto;
    height: 1.4rem;
    stroke: #53a78b;
    stroke-width: 2;
  }

  &:nth-of-type(3) {
    background-color: #53a78b;
    > div {
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
    }

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
`

const Label = styled.div`
  color: #53a78b;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 9px;
  letter-spacing: 0.05em;
  margin-top: 0.1875rem;
  text-transform: uppercase;
`
