import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import React, { memo, useEffect, useRef, useState } from 'react'

import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'

export interface Props {
  languagePrefix: string | null
  languages: LanguageItemProps[]
  variant?: Variant
}

export const Languages = memo(function Languages({
  languages,
  languagePrefix,
  variant = 'default',
}: Props) {
  const activeLanguage = useRef(null)

  const [othersLanguagesStatus, setOthersLanguagesStatus] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeLanguage.current && !activeLanguage.current.contains(e.target)) {
      setOthersLanguagesStatus(false)
    } else {
      // @ts-ignore
      if (othersLanguagesStatus) {
        setOthersLanguagesStatus(false)
      } else {
        setOthersLanguagesStatus(true)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [othersLanguagesStatus])

  return (
    <Container className="header-langs" ref={activeLanguage} variant={variant}>
      <LanguageActive
        variant={variant}
        className={othersLanguagesStatus ? 'open' : undefined}
        dial={4}
        row
      >
        {languages
          .filter((t) => t.languagePrefix === languagePrefix)
          .map((item, index) => (
            <LanguageItem className="modal" key={index} {...item} />
          ))}
        <svg width="9" height="7" viewBox="0 0 9 7">
          <path d="M4.5,0,9,7H0Z" />
        </svg>
      </LanguageActive>
      <OthersLanguages
        variant={variant}
        className={othersLanguagesStatus ? 'open' : undefined}
      >
        {languages
          .filter((t) => t.languagePrefix !== languagePrefix)
          .map((item, index) => (
            <LanguageItem className="modal" key={index} {...item} />
          ))}
      </OthersLanguages>
    </Container>
  )
})

const Container = styled.div<ContainerProps>`
  margin-right: 2.25rem;
  position: relative;
  z-index: 2;

  ${({ variant }) => {
    switch (variant) {
      case 'modal':
        return css`
          position: absolute;
          top: 10px;
          right: 0;

          @media (max-width: 1023px) {
            top: 0;
          }
        `
    }
  }}
`

const LanguageActive = styled(FlexBox)<ContainerProps>`
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 70px;
    margin-right: 1rem;
    opacity: 0;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
  }
  &.open {
    svg {
      transform: scaleY(1) translateY(-1px);
    }
  }
  svg {
    fill: #54a78b;
    margin-left: 0.75rem;
    transform: scaleY(-1);
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    &:before {
      height: 60px;
    }
  }

  @media (max-width: 767px) {
    &:before {
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'modal':
        return css`
          .modal {
            color: ${theme.colors.variants.neutralDark4};
            font-weight: 500;
          }
        `
    }
  }}
`

const OthersLanguages = styled.div<ContainerProps>`
  width: 2.125rem;
  opacity: 0;
  padding: 0 1rem;
  position: absolute;
  top: 1.875rem;
  left: 0;
  transform: translateY(2.25rem);
  transition: 0.3s ease-in-out;
  visibility: hidden;
  z-index: -1;

  &.open {
    opacity: 1;
    transform: translateY(1.5rem);
    visibility: visible;
    z-index: 1;
  }
  a {
    display: block;
    margin-bottom: 0.5625rem;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'modal':
        return css`
          .modal {
            color: ${theme.colors.variants.neutralDark4};
            font-weight: 500;
          }
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'modal'
