import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  pageID?: string
  label: string
  languagePrefix: string | null
  URL: string
  className?: string
}

export const LanguageItem = memo(function LanguageItem({
  label,
  languagePrefix,
  URL,
  className,
}: Props) {
  return (
    <Anchor
      className={className}
      to={URL}
      lang={languagePrefix === label ? undefined : label}
    >
      {label}
    </Anchor>
  )
})

const Anchor = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  line-height: 1.125rem;
  text-transform: uppercase;
`
