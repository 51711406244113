import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import Fade from 'react-reveal/Fade'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  backgroundImage?: { [key: string]: any }
  country?: string
  email?: string
  fax?: string
  IBEID?: number
  languageCode: string
  languagePrefix: string | null
  logo?: string
  locality?: string
  menuItems: MenuItemProps[]
  navigationStatus?: boolean
  phone?: string
  postalCode?: string
  region?: string
  siteName?: string
  whatsapp?: string
}

export const Navigation = memo(function Navigation({
  address,
  country,
  email,
  IBEID,
  languageCode,
  languagePrefix,
  locality,
  menuItems,
  navigationStatus,
  phone,
  postalCode,
  siteName,
}: Props) {
  const contactsRef = useRef(null)

  return (
    <Container row>
      <LeftSide>
        {menuItems ? (
          <Nav>
            <Fade left cascade duration={600} when={navigationStatus}>
              <NavList>
                {menuItems.map((item, index) => (
                  <NavLi key={index}>
                    <MenuItem {...item} />
                  </NavLi>
                ))}
              </NavList>
            </Fade>
          </Nav>
        ) : null}
        <LeftBottom
          className={navigationStatus ? 'visible' : undefined}
          dial={7}
          ref={contactsRef}
          row
          space="between"
        >
          <Contacts>
            {address ? (
              <Address>{`${address} - ${postalCode} ${locality}, ${country}`}</Address>
            ) : null}
            {phone ? (
              <ContactsItem>
                <Anchor
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Mobile Phone',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData('ph', languageCode)}: ${phone}`}
                </Anchor>
              </ContactsItem>
            ) : null}
            {email ? (
              <Email
                href={`mailto:${email}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {`${useVocabularyData('email', languageCode)}: ${email}`}
              </Email>
            ) : null}
          </Contacts>
          {IBEID ? (
            <CTA
              arrow
              className={navigationStatus ? 'visible' : undefined}
              URL={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
                languagePrefix ? languagePrefix : 'it'
              }`}
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
            />
          ) : null}
        </LeftBottom>
      </LeftSide>
      <RightSide dial={5}>
        <BackgroundImage src="/header_bg.webp" alt={siteName} />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 1023px) {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
`

const LeftSide = styled(FlexBox)`
  width: 65%;
  height: 100%;
  padding: 17vh 8.5vw 21vh;
  position: relative;

  @media (max-width: 1440px) {
    padding: 15vh 8.5vw 21vh;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 120px 40px 60px;
  }
`

const Nav = styled.nav`
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const NavList = styled.ul`
  height: 60vh;
  display: flex;
  flex-flow: column wrap;

  @media (min-height: 1080px) {
    max-height: 600px;
  }

  @media (max-width: 1023px) {
    display: block;
    height: auto;
    text-align: center;
  }
`

const NavLi = styled.li`
  width: 22.917vw;
  margin-bottom: 3.555vh;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`

const LeftBottom = styled(FlexBox)`
  width: calc(100% - 16.042vw);
  position: absolute;
  bottom: 11vh;
  left: 8.5vw;
  opacity: 0;
  transform: translateY(3.75rem);

  @media (max-width: 1440px) {
    bottom: 9vh;
  }

  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 3.75rem;
    position: relative;
    bottom: auto;
    left: auto;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`

const Contacts = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (min-width: 1024px) {
    padding-right: 30px;
  }

  @media (max-width: 1023px) {
    text-align: center;
  }

  @media (max-width: 767px) {
    text-align: left;
  }
`

const ContactsItem = styled.div``

const CTA = styled(Button)`
  @media (max-width: 1023px) {
    margin: 50px 0;
  }
`

const Address = styled.div``

const Anchor = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const Email = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const RightSide = styled(FlexBox)`
  width: 35%;
  height: 100%;
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`
