import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import { memo } from 'react'

export interface Props {
  languageCode: string
  onClick: () => void
}

export const ModalClose = memo(function ModalClose({
  languageCode,
  onClick,
}: Props) {
  return (
    <Container onClick={onClick}>
      <ModalCloseLabel>
        {useVocabularyData('close', languageCode)}
      </ModalCloseLabel>
    </Container>
  )
})

const Container = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 40px;
  right: auto;
  z-index: 2;
  transition: 0.3s ease-out;
  &:hover {
    &:before,
    &:after {
      transform: none;
    }
  }
  &:before,
  &:after {
    content: '';
    width: 38px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    position: absolute;
    top: 17px;
    right: 8px;
    transition: 0.3s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 1023px) {
    top: 1rem;
    left: 25px;

    &:before,
    &:after {
      width: 30px;
    }
  }
`

const ModalCloseLabel = styled.span`
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`
