import styled from '@emotion/styled'
import { Toolbars } from 'app/components/Common/Toolbars'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  bookingOffice?: string
  email?: string
  facebookURL?: string
  fax?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  phone?: string
  siteName?: string
  vat?: string
  whatsapp?: string
}

export const Footer = memo(function Footer({
  address,
  email,
  IBEID,
  languageCode,
  languagePrefix,
  phone,
  siteName,
  vat,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Head row space="between" wrap>
          <Cell>
            <Logo
              src="/logo-footer.svg"
              alt={siteName}
              width="187"
              height="92.5"
            />
            {vat ? (
              <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
            ) : null}
          </Cell>
          <Cell>
            <Label>{siteName}</Label>
            {address ? <Address>{address}</Address> : null}
          </Cell>
          <Cell>
            <Label>{useVocabularyData('contacts', languageCode)}</Label>
            {phone ? (
              <Contact>
                <Anchor
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Mobile Phone',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData('ph', languageCode)}: ${phone}`}
                </Anchor>
              </Contact>
            ) : null}
            {email ? (
              <Contact>
                <Email
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {`${useVocabularyData('email', languageCode)}: ${email}`}
                </Email>
              </Contact>
            ) : null}
            {vat ? (
              <Vat className="vat--mobile">{`${useVocabularyData(
                'vat',
                languageCode,
              )} ${vat}`}</Vat>
            ) : null}
          </Cell>
        </Head>
        <Bottom dial={7} row space="between" wrap>
          <Cell>
            <Policy row wrap>
              <Link
                to={`${
                  languagePrefix ? `/${languagePrefix}` : ''
                }/privacy-policy`}
              >
                {useVocabularyData('privacy-policy', languageCode)}
              </Link>
            </Policy>
          </Cell>
          <Credits href="https://www.qnt.it" target="_blank" rel="noopener">
            webdesign <b>QNT Hospitality</b>
          </Credits>
        </Bottom>
      </Wrapper>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <Toolbars
            languageCode={languageCode}
            languagePrefix={languagePrefix || null}
            email={email}
            phone={phone}
            IBEID={IBEID}
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: #edf4f1;
`

const Wrapper = styled.div`
  margin: auto;
  padding: 105px 15.5vw 50px;

  @media (max-width: 1199px) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  @media (max-width: 992px) {
    padding-top: 60px;
  }

  @media (max-width: 767px) {
    padding-bottom: 6.875rem;
  }
`

const Logo = styled.img`
  display: block;
  margin: 0 auto 40px;

  @media (max-width: 992px) {
    margin: 0 0 40px;
  }
`

const Head = styled(FlexBox)`
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #181f1d;
    opacity: 0.1;
    margin: 27px 0;

    @media (max-width: 992px) {
      margin: 45px 0 50px;
    }
  }
`

const Cell = styled.div`
  max-width: 19.444vw;

  @media (max-width: 1199px) {
    max-width: none;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`

const Label = styled.div`
  color: #53a78b;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  line-height: 1.75rem;
  margin-bottom: 8px;
  text-transform: uppercase;
  opacity: 0.9;

  @media (max-width: 992px) {
    margin-bottom: 5px;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  max-width: 300px;

  @media (max-width: 992px) {
    margin-bottom: 15px;
    font-weight: normal;
  }
`

const Contact = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (max-width: 992px) {
    font-weight: normal;
  }
`

const Anchor = styled.a`
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #53a78b;
  }
`

const Email = styled.a`
  transition: color 0.3s ease-in-out;
  text-decoration: underline;

  &:hover {
    color: #53a78b;
  }
`

const Bottom = styled(FlexBox)``

const Vat = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (max-width: 992px) {
    display: none;
    font-weight: normal;
    margin-top: 20px;
  }

  &.vat--mobile {
    display: none;

    @media (max-width: 992px) {
      display: block;
    }
  }
`

const Policy = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (max-width: 992px) {
    font-weight: normal;
  }

  a {
    margin-right: 1.125rem;

    @media (max-width: 992px) {
      margin-bottom: 10px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

const Credits = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (max-width: 992px) {
    font-weight: normal;
  }

  b {
    font-weight: 600;

    @media (max-width: 992px) {
      font-weight: 500;
    }
  }
`
