import { Props as StructuredDataProps } from 'app/components/StructuredData'
import { StructuredDataDataQuery } from 'graphql-types'
import { compact } from 'lodash'

export const getStructuredDataProps = (
  data: StructuredDataDataQuery,
  languageCode: string,
): StructuredDataProps | undefined => {
  const company = data.cms.company

  const address = company?.address
  const aggregateRatingValue =
    company?.rich_snippet_aggregate_rating_value || undefined
  const aggregateBestRating =
    company?.rich_snippet_aggregate_best_rating || undefined
  const aggregateRatingCount =
    company?.rich_snippet_aggregate_rating_count || undefined
  const country = company?.country
  const facebookURL = company?.facebook || undefined
  const googleMapsURL = company?.google_maps_url || undefined
  const latidude = company?.latitude
  const longitude = company?.longitude
  const locality = company?.locality
  const numberOfRooms = company?.rich_snippet_number_of_rooms || undefined
  const petsAllowed = company?.rich_snippet_pets_allowed || undefined
  const phone = company?.phone
  const postalCode = company?.postal_code
  const priceRange = company?.rich_snippet_price_range || undefined
  const region = company?.region
  const siteImage = compact(data.cms.home?.hero_images)
    .sort((a: any, b: any) => a.sort - b.sort)
    .map(
      ({ directus_files_id }) =>
        directus_files_id?.file?.childImageSharp?.gatsbyImageData.images
          .fallback.src,
    )[0]
  const siteName = data.site?.siteMetadata?.title
  const siteURL = data.site?.siteMetadata?.siteUrl

  return address &&
    country &&
    latidude &&
    longitude &&
    locality &&
    phone &&
    postalCode &&
    region &&
    siteImage &&
    siteName &&
    siteURL
    ? {
        address,
        aggregateRatingValue,
        aggregateBestRating,
        aggregateRatingCount,
        country,
        facebookURL,
        googleMapsURL,
        languageCode,
        latidude,
        longitude,
        locality,
        numberOfRooms,
        petsAllowed,
        phone,
        postalCode,
        priceRange,
        region,
        siteImage,
        siteName,
        siteURL,
      }
    : undefined
}
